/*
  Description: List of countries in Alpha-2 code format
  Source: https://www.iso.org/iso-3166-country-codes.html
 */

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export const Countries = [
  { code: CountryCode.AD, name: 'Andorra' },
  { code: CountryCode.AE, name: 'United Arab Emirates' },
  { code: CountryCode.AF, name: 'Afghanistan' },
  { code: CountryCode.AG, name: 'Antigua and Barbuda' },
  { code: CountryCode.AI, name: 'Anguilla' },
  { code: CountryCode.AL, name: 'Albania' },
  { code: CountryCode.AM, name: 'Armenia' },
  { code: CountryCode.AO, name: 'Angola' },
  { code: CountryCode.AQ, name: 'Antarctica' },
  { code: CountryCode.AR, name: 'Argentina' },
  { code: CountryCode.AS, name: 'American Samoa' },
  { code: CountryCode.AT, name: 'Austria' },
  { code: CountryCode.AU, name: 'Australia' },
  { code: CountryCode.AW, name: 'Aruba' },
  { code: CountryCode.AX, name: 'Åland Islands' },
  { code: CountryCode.AZ, name: 'Azerbaijan' },
  { code: CountryCode.BA, name: 'Bosnia and Herzegovina' },
  { code: CountryCode.BB, name: 'Barbados' },
  { code: CountryCode.BD, name: 'Bangladesh' },
  { code: CountryCode.BE, name: 'Belgium' },
  { code: CountryCode.BF, name: 'Burkina Faso' },
  { code: CountryCode.BG, name: 'Bulgaria' },
  { code: CountryCode.BH, name: 'Bahrain' },
  { code: CountryCode.BI, name: 'Burundi' },
  { code: CountryCode.BJ, name: 'Benin' },
  { code: CountryCode.BL, name: 'Saint Barthélemy' },
  { code: CountryCode.BM, name: 'Bermuda' },
  { code: CountryCode.BN, name: 'Brunei Darussalam' },
  { code: CountryCode.BO, name: 'Bolivia, Plurinational State of' },
  { code: CountryCode.BQ, name: 'Bonaire, Sint Eustatius and Saba' },
  { code: CountryCode.BR, name: 'Brazil' },
  { code: CountryCode.BS, name: 'Bahamas' },
  { code: CountryCode.BT, name: 'Bhutan' },
  { code: CountryCode.BV, name: 'Bouvet Island' },
  { code: CountryCode.BW, name: 'Botswana' },
  { code: CountryCode.BY, name: 'Belarus' },
  { code: CountryCode.BZ, name: 'Belize' },
  { code: CountryCode.CA, name: 'Canada' },
  { code: CountryCode.CC, name: 'Cocos (Keeling) Islands' },
  { code: CountryCode.CD, name: 'Congo, Democratic Republic of the' },
  { code: CountryCode.CF, name: 'Central African Republic' },
  { code: CountryCode.CG, name: 'Congo' },
  { code: CountryCode.CH, name: 'Switzerland' },
  { code: CountryCode.CI, name: "Côte d'Ivoire" },
  { code: CountryCode.CK, name: 'Cook Islands' },
  { code: CountryCode.CL, name: 'Chile' },
  { code: CountryCode.CM, name: 'Cameroon' },
  { code: CountryCode.CN, name: 'China' },
  { code: CountryCode.CO, name: 'Colombia' },
  { code: CountryCode.CR, name: 'Costa Rica' },
  { code: CountryCode.CU, name: 'Cuba' },
  { code: CountryCode.CV, name: 'Cabo Verde' },
  { code: CountryCode.CW, name: 'Curaçao' },
  { code: CountryCode.CX, name: 'Christmas Island' },
  { code: CountryCode.CY, name: 'Cyprus' },
  { code: CountryCode.CZ, name: 'Czechia' },
  { code: CountryCode.DE, name: 'Germany' },
  { code: CountryCode.DJ, name: 'Djibouti' },
  { code: CountryCode.DK, name: 'Denmark' },
  { code: CountryCode.DM, name: 'Dominica' },
  { code: CountryCode.DO, name: 'Dominican Republic' },
  { code: CountryCode.DZ, name: 'Algeria' },
  { code: CountryCode.EC, name: 'Ecuador' },
  { code: CountryCode.EE, name: 'Estonia' },
  { code: CountryCode.EG, name: 'Egypt' },
  { code: CountryCode.EH, name: 'Western Sahara' },
  { code: CountryCode.ER, name: 'Eritrea' },
  { code: CountryCode.ES, name: 'Spain' },
  { code: CountryCode.ET, name: 'Ethiopia' },
  { code: CountryCode.FI, name: 'Finland' },
  { code: CountryCode.FJ, name: 'Fiji' },
  { code: CountryCode.FK, name: 'Falkland Islands (Malvinas)' },
  { code: CountryCode.FM, name: 'Micronesia, Federated States of' },
  { code: CountryCode.FO, name: 'Faroe Islands' },
  { code: CountryCode.FR, name: 'France' },
  { code: CountryCode.GA, name: 'Gabon' },
  { code: CountryCode.GB, name: 'United Kingdom of Great Britain and Northern Ireland' },
  { code: CountryCode.GD, name: 'Grenada' },
  { code: CountryCode.GE, name: 'Georgia' },
  { code: CountryCode.GF, name: 'French Guiana' },
  { code: CountryCode.GG, name: 'Guernsey' },
  { code: CountryCode.GH, name: 'Ghana' },
  { code: CountryCode.GI, name: 'Gibraltar' },
  { code: CountryCode.GL, name: 'Greenland' },
  { code: CountryCode.GM, name: 'Gambia' },
  { code: CountryCode.GN, name: 'Guinea' },
  { code: CountryCode.GP, name: 'Guadeloupe' },
  { code: CountryCode.GQ, name: 'Equatorial Guinea' },
  { code: CountryCode.GR, name: 'Greece' },
  { code: CountryCode.GS, name: 'South Georgia and the South Sandwich Islands' },
  { code: CountryCode.GT, name: 'Guatemala' },
  { code: CountryCode.GU, name: 'Guam' },
  { code: CountryCode.GW, name: 'Guinea-Bissau' },
  { code: CountryCode.GY, name: 'Guyana' },
  { code: CountryCode.HK, name: 'Hong Kong' },
  { code: CountryCode.HM, name: 'Heard Island and McDonald Islands' },
  { code: CountryCode.HN, name: 'Honduras' },
  { code: CountryCode.HR, name: 'Croatia' },
  { code: CountryCode.HT, name: 'Haiti' },
  { code: CountryCode.HU, name: 'Hungary' },
  { code: CountryCode.ID, name: 'Indonesia' },
  { code: CountryCode.IE, name: 'Ireland' },
  { code: CountryCode.IL, name: 'Israel' },
  { code: CountryCode.IM, name: 'Isle of Man' },
  { code: CountryCode.IN, name: 'India' },
  { code: CountryCode.IO, name: 'British Indian Ocean Territory' },
  { code: CountryCode.IQ, name: 'Iraq' },
  { code: CountryCode.IR, name: 'Iran, Islamic Republic of' },
  { code: CountryCode.IS, name: 'Iceland' },
  { code: CountryCode.IT, name: 'Italy' },
  { code: CountryCode.JE, name: 'Jersey' },
  { code: CountryCode.JM, name: 'Jamaica' },
  { code: CountryCode.JO, name: 'Jordan' },
  { code: CountryCode.JP, name: 'Japan' },
  { code: CountryCode.KE, name: 'Kenya' },
  { code: CountryCode.KG, name: 'Kyrgyzstan' },
  { code: CountryCode.KH, name: 'Cambodia' },
  { code: CountryCode.KI, name: 'Kiribati' },
  { code: CountryCode.KM, name: 'Comoros' },
  { code: CountryCode.KN, name: 'Saint Kitts and Nevis' },
  { code: CountryCode.KP, name: "Korea, Democratic People's Republic of" },
  { code: CountryCode.KR, name: 'Korea, Republic of' },
  { code: CountryCode.KW, name: 'Kuwait' },
  { code: CountryCode.KY, name: 'Cayman Islands' },
  { code: CountryCode.KZ, name: 'Kazakhstan' },
  { code: CountryCode.LA, name: "Lao People's Democratic Republic" },
  { code: CountryCode.LB, name: 'Lebanon' },
  { code: CountryCode.LC, name: 'Saint Lucia' },
  { code: CountryCode.LI, name: 'Liechtenstein' },
  { code: CountryCode.LK, name: 'Sri Lanka' },
  { code: CountryCode.LR, name: 'Liberia' },
  { code: CountryCode.LS, name: 'Lesotho' },
  { code: CountryCode.LT, name: 'Lithuania' },
  { code: CountryCode.LU, name: 'Luxembourg' },
  { code: CountryCode.LV, name: 'Latvia' },
  { code: CountryCode.LY, name: 'Libya' },
  { code: CountryCode.MA, name: 'Morocco' },
  { code: CountryCode.MC, name: 'Monaco' },
  { code: CountryCode.MD, name: 'Moldova, Republic of' },
  { code: CountryCode.ME, name: 'Montenegro' },
  { code: CountryCode.MF, name: 'Saint Martin, (French part)' },
  { code: CountryCode.MG, name: 'Madagascar' },
  { code: CountryCode.MH, name: 'Marshall Islands' },
  { code: CountryCode.MK, name: 'North Macedonia' },
  { code: CountryCode.ML, name: 'Mali' },
  { code: CountryCode.MM, name: 'Myanmar' },
  { code: CountryCode.MN, name: 'Mongolia' },
  { code: CountryCode.MO, name: 'Macao' },
  { code: CountryCode.MP, name: 'Northern Mariana Islands' },
  { code: CountryCode.MQ, name: 'Martinique' },
  { code: CountryCode.MR, name: 'Mauritania' },
  { code: CountryCode.MS, name: 'Montserrat' },
  { code: CountryCode.MT, name: 'Malta' },
  { code: CountryCode.MU, name: 'Mauritius' },
  { code: CountryCode.MV, name: 'Maldives' },
  { code: CountryCode.MW, name: 'Malawi' },
  { code: CountryCode.MX, name: 'Mexico' },
  { code: CountryCode.MY, name: 'Malaysia' },
  { code: CountryCode.MZ, name: 'Mozambique' },
  { code: CountryCode.NA, name: 'Namibia' },
  { code: CountryCode.NC, name: 'New Caledonia' },
  { code: CountryCode.NE, name: 'Niger' },
  { code: CountryCode.NF, name: 'Norfolk Island' },
  { code: CountryCode.NG, name: 'Nigeria' },
  { code: CountryCode.NI, name: 'Nicaragua' },
  { code: CountryCode.NL, name: 'Netherlands' },
  { code: CountryCode.NO, name: 'Norway' },
  { code: CountryCode.NP, name: 'Nepal' },
  { code: CountryCode.NR, name: 'Nauru' },
  { code: CountryCode.NU, name: 'Niue' },
  { code: CountryCode.NZ, name: 'New Zealand' },
  { code: CountryCode.OM, name: 'Oman' },
  { code: CountryCode.PA, name: 'Panama' },
  { code: CountryCode.PE, name: 'Peru' },
  { code: CountryCode.PF, name: 'French Polynesia' },
  { code: CountryCode.PG, name: 'Papua New Guinea' },
  { code: CountryCode.PH, name: 'Philippines' },
  { code: CountryCode.PK, name: 'Pakistan' },
  { code: CountryCode.PL, name: 'Poland' },
  { code: CountryCode.PM, name: 'Saint Pierre and Miquelon' },
  { code: CountryCode.PN, name: 'Pitcairn' },
  { code: CountryCode.PR, name: 'Puerto Rico' },
  { code: CountryCode.PS, name: 'Palestine, State of' },
  { code: CountryCode.PT, name: 'Portugal' },
  { code: CountryCode.PW, name: 'Palau' },
  { code: CountryCode.PY, name: 'Paraguay' },
  { code: CountryCode.QA, name: 'Qatar' },
  { code: CountryCode.RE, name: 'Réunion' },
  { code: CountryCode.RO, name: 'Romania' },
  { code: CountryCode.RS, name: 'Serbia' },
  { code: CountryCode.RU, name: 'Russian Federation' },
  { code: CountryCode.RW, name: 'Rwanda' },
  { code: CountryCode.SA, name: 'Saudi Arabia' },
  { code: CountryCode.SB, name: 'Solomon Islands' },
  { code: CountryCode.SC, name: 'Seychelles' },
  { code: CountryCode.SD, name: 'Sudan' },
  { code: CountryCode.SE, name: 'Sweden' },
  { code: CountryCode.SG, name: 'Singapore' },
  { code: CountryCode.SH, name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: CountryCode.SI, name: 'Slovenia' },
  { code: CountryCode.SJ, name: 'Svalbard and Jan Mayen' },
  { code: CountryCode.SK, name: 'Slovakia' },
  { code: CountryCode.SL, name: 'Sierra Leone' },
  { code: CountryCode.SM, name: 'San Marino' },
  { code: CountryCode.SN, name: 'Senegal' },
  { code: CountryCode.SO, name: 'Somalia' },
  { code: CountryCode.SR, name: 'Suriname' },
  { code: CountryCode.SS, name: 'South Sudan' },
  { code: CountryCode.ST, name: 'Sao Tome and Principe' },
  { code: CountryCode.SV, name: 'El Salvador' },
  { code: CountryCode.SX, name: 'Sint Maarten, (Dutch part)' },
  { code: CountryCode.SY, name: 'Syrian Arab Republic' },
  { code: CountryCode.SZ, name: 'Eswatini' },
  { code: CountryCode.TC, name: 'Turks and Caicos Islands' },
  { code: CountryCode.TD, name: 'Chad' },
  { code: CountryCode.TF, name: 'French Southern Territories' },
  { code: CountryCode.TG, name: 'Togo' },
  { code: CountryCode.TH, name: 'Thailand' },
  { code: CountryCode.TJ, name: 'Tajikistan' },
  { code: CountryCode.TK, name: 'Tokelau' },
  { code: CountryCode.TL, name: 'Timor-Leste' },
  { code: CountryCode.TM, name: 'Turkmenistan' },
  { code: CountryCode.TN, name: 'Tunisia' },
  { code: CountryCode.TO, name: 'Tonga' },
  { code: CountryCode.TR, name: 'Türkiye' },
  { code: CountryCode.TT, name: 'Trinidad and Tobago' },
  { code: CountryCode.TV, name: 'Tuvalu' },
  { code: CountryCode.TW, name: 'Taiwan, Province of China' },
  { code: CountryCode.TZ, name: 'Tanzania, United Republic of' },
  { code: CountryCode.UA, name: 'Ukraine' },
  { code: CountryCode.UG, name: 'Uganda' },
  { code: CountryCode.UM, name: 'United States Minor Outlying Islands' },
  { code: CountryCode.US, name: 'United States of America' },
  { code: CountryCode.UY, name: 'Uruguay' },
  { code: CountryCode.UZ, name: 'Uzbekistan' },
  { code: CountryCode.VA, name: 'Holy See' },
  { code: CountryCode.VC, name: 'Saint Vincent and the Grenadines' },
  { code: CountryCode.VE, name: 'Venezuela, Bolivarian Republic of' },
  { code: CountryCode.VG, name: 'Virgin Islands, British' },
  { code: CountryCode.VI, name: 'Virgin Islands, U.S.' },
  { code: CountryCode.VN, name: 'Viet Nam' },
  { code: CountryCode.VU, name: 'Vanuatu' },
  { code: CountryCode.WF, name: 'Wallis and Futuna' },
  { code: CountryCode.WS, name: 'Samoa' },
  { code: CountryCode.YE, name: 'Yemen' },
  { code: CountryCode.YT, name: 'Mayotte' },
  { code: CountryCode.ZA, name: 'South Africa' },
  { code: CountryCode.ZM, name: 'Zambia' },
  { code: CountryCode.ZW, name: 'Zimbabwe' },
];

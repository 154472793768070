import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { TeamProfileHeaderFeature } from 'features/dashboard/team-profile-header-feature/TeamProfileHeader.feature';
import { TeamProfileDashboardWidget } from 'widgets/dashboard/TeamProfileDashboard.widget';

type Props = {
  teamId: string;
};

export const TeamProfile = ({ teamId }: Props) => {
  const { data: team } = useTeamWithDefaults(teamId);

  const [selectedSeasonYear, setSelectedSeasonYear] = useState<number | undefined>();
  const [selectedCompetition, setSelectedCompetition] = useState<string | undefined>();

  useEffect(() => {
    if (!team) return;

    setSelectedSeasonYear(team.teamDefaults?.season?.year);
    setSelectedCompetition(team.teamDefaults?.season?.competition?.id);
  }, [team]);

  if (!team) return null;

  return (
    <>
      <Stack gap={2} padding={(theme) => theme.spacing(1.5, 4, 2, 4)}>
        <TeamProfileHeaderFeature />
      </Stack>

      {selectedCompetition && selectedSeasonYear && (
        <TeamProfileDashboardWidget
          key={`${selectedCompetition}-${selectedSeasonYear}`}
          competitionId={selectedCompetition}
          seasonYear={selectedSeasonYear}
          team={team}
        />
      )}
    </>
  );
};
